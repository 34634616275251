import { Stack } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import { getBrokerCompensationLink } from 'actions/additionalDisclosures/getBrokerCompensationLink';
import { ISelectedPlansDto, TaskDto } from 'api/generated/models';
import ExternalLink from 'components/ExternalLink';
import { ANTHEM_DISCLOSURES_PDF_URL } from 'constants/miscUrls';
import { ANTHEM_ISSUER_NAME } from 'constants/selectedPlans';
import useThunkDispatch from 'hooks/useThunkDispatch';
import React, { useEffect, useMemo } from 'react';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { AppStore } from 'reducers/appReducer';
import { hasValue } from 'utilities/index';

type IAdditionalDisclosuresProps = {
    previousTask: TaskDto | undefined;
};

const AdditionalDisclosures = ({ previousTask }: IAdditionalDisclosuresProps) => {
    const dispatch = useThunkDispatch();
    const { brokerCompensationLink, carrierName, homeState } = useSelector((state: AppStore) => ({
        brokerCompensationLink: state.brokerCompensationLink,
        carrierName: state.pathwayUser.carrierName,
        homeState: state.current.userProfile.address?.state,
    }));

    const showAnthemDisclosures = useMemo(
        () =>
            (previousTask?.response as ISelectedPlansDto)?.planInfoDtos?.[0]?.plan?.issuer?.name ===
            ANTHEM_ISSUER_NAME,
        [previousTask]
    );

    useEffect(() => {
        if (hasValue(carrierName) && hasValue(homeState)) {
            dispatch(getBrokerCompensationLink(homeState, carrierName));
        }
    }, [carrierName, dispatch, homeState]);

    if (!hasValue(brokerCompensationLink) && !showAnthemDisclosures) {
        return <React.Fragment />;
    }

    return (
        <Card sx={{ mt: 1 }}>
            <CardHeader title="Additional Disclosures" />
            <CardContent>
                <Stack alignItems="center" spacing={2}>
                    {hasValue(brokerCompensationLink) && (
                        <ExternalLink href={brokerCompensationLink}>
                            {brokerCompensationLink}
                        </ExternalLink>
                    )}
                    {showAnthemDisclosures && (
                        <ExternalLink href={ANTHEM_DISCLOSURES_PDF_URL}>
                            Anthem Disclosures
                        </ExternalLink>
                    )}
                </Stack>
            </CardContent>
        </Card>
    );
};

export default hot(module)(AdditionalDisclosures);
