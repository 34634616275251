import ContentWithLabel from 'components/verifiedInfoSummary/ContentWithLabel';
import Section from 'components/verifiedInfoSummary/Section';
import { SurveyTabs } from 'constants/surveyTabs';
import { ISurveyState } from 'pages/survey/surveyState';
import React, { useMemo } from 'react';
import Col from 'react-bootstrap/Col';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { AppStore } from 'reducers/appReducer';
import { genderList } from 'reducers/options';
import { reduceToObject } from 'utilities';
import { formatDateForDisplay, formatStateNameFromAbbrev, formatYesOrNo } from 'utilities/format';

const AboutYou = ({
    dateOfBirth,
    editSection,
    firstName,
    gender,
    hireDate,
    isIncarcerated,
    isLegalResident,
    jobTitle,
    lastName,
    preferredName,
    usCitizen,
}: ISurveyState['member'] & { editSection: ((tabIndex: number) => void) | undefined }) => {
    const { stateAbbrev, states } = useSelector((appState: AppStore) => ({
        stateAbbrev: appState.surveyState.contact.state,
        states: appState.states,
    }));

    const genders = useMemo(() => reduceToObject(genderList, 'id', 'value'), []);
    const selectedStateName = useMemo(() => formatStateNameFromAbbrev(states, stateAbbrev), [
        stateAbbrev,
        states,
    ]);

    return (
        <Section onEdit={editSection && (() => editSection(SurveyTabs.Member))} title="About You">
            <Col className="p-0">
                <ContentWithLabel label="Legal First Name">{firstName}</ContentWithLabel>
                <ContentWithLabel label="Last Name">{lastName}</ContentWithLabel>
                {preferredName && (
                    <ContentWithLabel label="Preferred First Name">
                        {preferredName}
                    </ContentWithLabel>
                )}
                <ContentWithLabel label="Gender">{genders[gender as string]}</ContentWithLabel>
                <ContentWithLabel label="Date of Birth">
                    {formatDateForDisplay(dateOfBirth)}
                </ContentWithLabel>
                <ContentWithLabel label="Job Title">{jobTitle}</ContentWithLabel>
                <ContentWithLabel label="Hire Date">
                    {formatDateForDisplay(hireDate)}
                </ContentWithLabel>
                <ContentWithLabel label="U.S. Citizen">{formatYesOrNo(usCitizen)}</ContentWithLabel>
                <ContentWithLabel label={`Legal Resident of ${selectedStateName}`}>
                    {formatYesOrNo(isLegalResident)}
                </ContentWithLabel>
                <ContentWithLabel label="Incarcerated">
                    {formatYesOrNo(isIncarcerated)}
                </ContentWithLabel>
            </Col>
        </Section>
    );
};

export default hot(module)(AboutYou);
