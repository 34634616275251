import Typography from '@mui/material/Typography';
import { HouseholdMemberTypes, SurveyTypes } from 'api/generated/enums';
import { ISurveyHouseholdMemberDto } from 'api/generated/models';
import ContentWithLabel from 'components/verifiedInfoSummary/ContentWithLabel';
import GENDERS from 'constants/genders';
import useUserProps from 'hooks/useUserProps';
import isEmpty from 'lodash/isEmpty';
import AddressInfo from 'pages/profile/AddressInfo';
import React, { useMemo } from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { AppStore } from 'reducers/appReducer';
import { getEnumNames } from 'utilities';
import {
    formatDateForDisplay,
    formatGender,
    formatStateNameFromAbbrev,
    formatYesOrNo,
} from 'utilities/format';

const HouseholdMemberItem = ({
    address,
    dateOfBirth,
    firstName,
    gender,
    householdMemberTypeId,
    isIncarcerated,
    isLegalResident,
    lastName,
    preferredName,
    usCitizen,
}: ISurveyHouseholdMemberDto) => {
    const { isInIchraPathway, user } = useUserProps();
    const { stateAbbrev, states } = useSelector((appState: AppStore) => ({
        stateAbbrev: appState.surveyState.contact.state,
        states: appState.states,
    }));

    const householdMemberType = getEnumNames(HouseholdMemberTypes)[householdMemberTypeId];
    const houseHoldMemberStateAbbrev = address ? address.state : stateAbbrev;
    const selectedStateName = useMemo(
        () => formatStateNameFromAbbrev(states, houseHoldMemberStateAbbrev),
        [houseHoldMemberStateAbbrev, states]
    );

    const getMemberLabelAndName = () => {
        let label;
        if (householdMemberType === 'Spouse') {
            label = 'Spouse';
        } else {
            if (user?.surveyTypeToSend === SurveyTypes.ICHRA || isInIchraPathway) {
                label = 'Child';
            } else if (
                user?.surveyTypeToSend === SurveyTypes.Standard &&
                householdMemberTypeId !== HouseholdMemberTypes.Unclaimed
            ) {
                label = 'Dependent';
            } else {
                label = 'Unclaimed';
            }
        }
        return `${label} - ${firstName}`;
    };

    return (
        <div>
            <div className="d-flex align-items-center justify-content-between row text-secondary">
                <Typography variant="h5">{getMemberLabelAndName()}</Typography>
            </div>
            <Row>
                <Col className="p-0">
                    <ContentWithLabel label="Last Name">{lastName}</ContentWithLabel>
                    {preferredName && (
                        <ContentWithLabel label="Preferred First Name">
                            {preferredName}
                        </ContentWithLabel>
                    )}
                    <ContentWithLabel label="Gender">
                        {formatGender(gender as GENDERS)}
                    </ContentWithLabel>
                    <ContentWithLabel label="Date of Birth">
                        {formatDateForDisplay(dateOfBirth)}
                    </ContentWithLabel>
                    {!isEmpty(address) && (
                        <ContentWithLabel label="Address">
                            <AddressInfo data={address} />
                        </ContentWithLabel>
                    )}
                    <ContentWithLabel label="U.S. Citizen">
                        {formatYesOrNo(usCitizen)}
                    </ContentWithLabel>
                    <ContentWithLabel label={`Legal Resident of ${selectedStateName}`}>
                        {formatYesOrNo(isLegalResident)}
                    </ContentWithLabel>
                    <ContentWithLabel label="Incarcerated">
                        {formatYesOrNo(isIncarcerated)}
                    </ContentWithLabel>
                </Col>
            </Row>
        </div>
    );
};
export default hot(module)(HouseholdMemberItem);
